<template>
    <div class='col-12 mt-3'>
        <div class='grid p-fluid'>
            <div class='col-12 md:col-6'>
                <DataTable
                    :value="rows"
                    @rowReorder='onRowReorder'
                    class="mt-3 p-shadow-1">
                    <template #empty>
                        Non ci sono capitoli.
                    </template>
                    <Column :row-reorder='true' header-style='width: 2rem'/>
                    <Column field='num' header="N" :style="{width:'5%'}"></Column>
                    <Column field='title' header="Titolo" :style="{width:'80%'}"></Column>
                    <Column header="" :style="{width:'15%'}">
                        <template #body="{data}">
                            <Button icon='pi pi-pencil' class='mr-2 p-button-info' title='Modifica'
                                    @click='startEdit(data)'></Button>
                            <Button icon="pi pi-minus" class="p-button-danger" @click='onRemove(data)'/>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class='col-12 md:col-6'>
                <Chapter v-model='editModel' @undo='stopEdit' @add='addNew' @save='saveChapter'/>
            </div>
        </div>
    </div>
</template>

<script>
import Chapter from './Chapter';
import Notifications from '../../../../mixins/notifications';
import tools from '../../../../libs/tools';
export default {
    mixins: [Notifications],
    components: { Chapter },
    data(){
        return {
            edit: -1,
            editModel: null
        }
    },
    emits: ['update:modelValue'],
    computed: {
        rows() {
            let counter = 0;
            return this.modelValue.map(v => {
                counter++;
                v.body = tools.models.sanitizeHtml(v.body);
                return {
                    num: counter,
                    title: v.title,
                    id: v.id,
                    row: v
                }
            })
        },
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    props: {
        modelValue: {
            type: Array,
            default(){
                return [];
            }
        },
    },
    methods: {
        onRowReorder(data){
            this.stopEdit();
            const newVal = [];
            data.value.forEach(r => {
               newVal.push(r.row);
            });
            this.value = newVal;
        },
        startEdit(model){
            this.edit = model.num;
            this.editModel = model.row;
        },
        stopEdit(){
            this.edit = -1;
            this.editModel = null;
        },
        onRemove(data){
            this.stopEdit();
            this.value = this.value.filter(row => row.id !== data.id);
        },
        saveChapter(data){
            if (data === null){
                this.ShowWarning('Strano', 'Pare non ci sia nulla da salvare');
                return null;
            }
            let counter = 0;
            this.value.forEach((r, idx) => {
                counter++;
                if (counter === this.edit){
                    this.value[idx] = data;
                }
            });
            this.ShowSuccess("Ricorda", "Le modifiche verranno salvate solo dopo il salvataggio della traduzione");
            this.stopEdit();

        },
        addNew(){
            const model = {
                id: 0,
                num: this.value.length + 1,
                title: "[New Chapter]",
                body: ""
            };

            this.value.push(model);
            this.edit = model.num;
            this.editModel = model;
        }
    }
}
</script>
