<template>
    <div>
        <Toolbar class='mt-3'>
            <template #end>
                <Button icon="pi pi-plus" title='Nuovo' @click="$emit('add')" class="mr-2" />
                <Button icon="pi pi-save" title="Salva" @click="save"  class="p-button-success mr-2" />
                <Button icon="pi pi-times" title="Annulla" @click="$emit('undo')" class="p-button-danger" />
            </template>
        </Toolbar>
        <template v-if='form !== null'>
            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12 '>
                    <span class='p-float-label'>
                        <InputText id='chap_title'
                                   type='text'
                                   v-model="v$.form.title.$model"
                                   :class="{'p-invalid': v$.form.title.$errors.length}"
                        />
                        <label for='chap_title'>Titolo</label>
                    </span>
                </div>
            </div>
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='form.body'
                        :key='"cont_book_chap_"+form.id'
                        contentType='html'/>
                    <label>Testo</label>
                </div>
            </div>
            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {  required } from '@vuelidate/validators';
import Notifications from '../../../../mixins/notifications';
export default {
    mixins: [Notifications],
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return {
            form: {
                title: {
                    required
                }
            }
        };
    },
    data(){
        return {
            form: null
        }
    },
    emits: ['update:modelValue', 'undo', 'add', 'save'],
    methods:{
        async save(){
            await this.v$.$validate();
            if (this.v$.form.$invalid) {
                this.ShowError("Attenzione", "Il titolo è obbligatorio");
                return;
            }
            this.$emit('save', this.form);
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    props: {
        modelValue: {
            type: Object,
            default(){
                return {
                    id: 0,
                    title: "",
                    body: ""
                };
            }
        },
    },
    watch:{
        modelValue(n){
            if (n === null){
                this.form = null;
                return;
            }
            this.form = {
                ...n
            };
        }
    }
}
</script>
