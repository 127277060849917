import { createNamespacedHelpers } from 'vuex';
import tools from '../libs/tools';

const contStore = createNamespacedHelpers('Cont');

export default {
    mounted(){
        this.getPublishers();
    },
    data(){
        return {
            dd_publishers: []
        };
    },
    computed: {
        ...contStore.mapGetters(['Publishers/rows', 'Publishers/loading']),
        publishersRefreshLoadingIcon(){
            return this['Publishers/loading'] ? "pi pi-spin pi-spinner" : "pi pi-refresh";
        }
    },
    methods: {
        ...contStore.mapActions(['Publishers/fetchList']),
        initPublishers(items){
            this.dd_publishers = tools.models.listsToDropdown(items);
        },
        refreshPublishers(){
            this['Publishers/fetchList']().then(items => {
                this.initPublishers(items);
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        getPublishers(){
            if (this['Publishers/rows'] && this['Publishers/rows'].length){
                this.initPublishers(this['Publishers/rows']);
            } else {
                this.refreshPublishers();
            }
        }

    }
}
