import tools from '../../../libs/tools';
import { minLength, required } from '@vuelidate/validators';
const mustHaveId = (value) => {
  if (!value) return false;
  if (!value.id) return false;
  return true;
};

const model = {
    id: "",
    s_aut: "",
    authors: [],
    slug: "",
    last_update: 0,
    translations: {}
};
const translation = {
    audio: {
        url: "",
        seconds: 0,
    },
    chapters: [],
    file: "",
    pages: 0,
    pub_at : null,
    publish_state: "",
    publisher: {
        id: "",
    },
    slug: "",
    store_info: {
        name: "",
        url: "",
    },
    last_update: 0,
    title: ""
};



export default {
    ...tools.models.apiCommons(model, translation, "bid"),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            authors: {
                required,
                minLength: minLength(1)
            }
        }
    },
    translationValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            publish_state: { required },
            publisher: {
                mustHaveId
            },
            title: { required },
            pub_at: {
                validDate: tools.models.validators.PUBLISH_DATE
            }
        }
    },
    uploads: [
        { el: "uAudioUrl", target: "audio.url", folder: "/audio/%L/%Y/%M", protect: true, urlChanger: url => url.replace('/audio/','/'), maxNum: 1, types: [tools.models.uploadFileTypes.SOUND]},
        { el: "uFile", target: "file", folder: "/books/%L/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.PDF]},
    ]

};
