<template>
    <div v-if='model && !model.is_null' :key='"cb_t_"+lang'>
        <div class='text-right last_update'>Data ultima modifica: {{ lastUpdateDate }}</div>

        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid'>
                <div class=' col-12'>
                    <Toolbar class='mt-2'>
                        <template v-slot:start>
                            <div><span class="publish_state">Stato di pubblicazione </span>
                                <Dropdown
                                    v-model="v$.model.publish_state.$model"
                                    :options="publish_states"
                                    optionLabel="label"
                                    optionValue="code"
                                    placeholder="Stato di pubblicazione" /></div>
                            <div class='ml-5'><span class="publish_state">Data di pubblicazione </span>
                                <Calendar
                                    :showTime="true"
                                    inputId="pub_at"
                                    v-model="v$.model.pub_at.$model"
                                    dateFormat="dd/mm/yy"
                                    :class="{'p-invalid': v$.model.pub_at.$invalid && v$.model.pub_at.$dirty}"
                                /></div>
                        </template>
                    </Toolbar>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12 md:col-8'>
                    <div class="p-inputgroup">
                        <span class='p-float-label'>
                            <InputText id='t_slug'
                                       type='text'
                                       v-model="v$.model.slug.$model"
                                       :class="{'p-invalid': v$.model.slug.$invalid && v$.model.slug.$dirty}"
                            />
                            <Button label='Fix' class="p-button-warning" title='Correggi SLUG' @click='model.slug = sluggify(model.slug)'/>
                            <Button icon="pi pi-angle-double-down" class="p-button-primary" title='Eredita' @click='model.slug = row.slug'/>
                            <Button icon="pi pi-sort-up" class="p-button-info" title='Dal titolo' @click='model.slug = sluggify(model.title)'/>
                            <label for='t_slug'>Slug</label>
                        </span>
                    </div>
                    <small>URL pubblico: non disponibile. A questo libro si accede su APP passando da un abstract collegato</small>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-10  md:col-7'>
                    <span class='p-float-label'>
                        <InputText id='title'
                                   type='text'
                                   v-model="v$.model.title.$model"
                                   :class="{'p-invalid': v$.model.title.$errors.length}"
                        />
                        <label for='title'>Titolo</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12 md:col-4'>
                    <uppy-dashboard
                        :uppy='uFile'
                        :props='uppyDashboardProps'
                    />
                </div>

                <div class='field col-12 md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='file'
                                   type='text'
                                   v-model="model.file"
                        />
                        <label for='cover_url'>File PDF</label>
                    </span>
                    <div v-if='model.file' class='text-center mt-3'>
                        <a :href='model.file' target='_blank'>
                            <font-awesome-icon
                                class='text-color danger pointer'
                                title='Preview'
                                :style='{marginTop: "15px"}'
                                :icon="['far', 'file-pdf']"
                                size='6x'
                            />
                        </a>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12 md:col-4'>
                    <uppy-dashboard
                        :uppy='uAudioUrl'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12 md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='uAudioUrl'
                                   type='text'
                                   v-model="model.audio.url"
                        />
                        <label for='uAudioUrl'>Audio</label>
                    </span>
                    <div class='grid p-fluid formgrid mt-5'>
                        <div class='field col-6  md:col-3'>
                            <span class='p-float-label'>
                                <InputNumber id='audio_seconds' mode='decimal' :useGrouping='false'
                                           v-model="model.audio.seconds"
                                />
                                <label for='audio_seconds'>Durata</label>
                            </span>
                        </div>
                    </div>
                    <div v-if='model.audio.url' class='text-center mt-3'>
                        <video controls name='audiourl' :style='{height: "50px", width:"400px"}'>
                            <source type='audio/mpeg' :src='openUrl'>
                        </video>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-3'>
                    <div class='p-inputgroup'>
                        <Button :icon="publishersRefreshLoadingIcon" class="p-button-warning" @click='refreshPublishers'/>
                        <span class='p-float-label'>
                        <Dropdown v-model="v$.model.publisher.$model"
                                  id='publisher'
                                  dataKey="id"
                                  :options="dd_publishers"
                                  optionLabel="label"
                                  :class="{'p-invalid': v$.model.publisher.$errors.length}"
                                  :filter="true"
                                  filterPlaceholder="Cerca"/>
                        <label for='publisher'>Casa Editrice</label>
                    </span>
                    </div>
                </div>

                <div class='field col-12  md:col-3'>
                    <span class='p-float-label'>
                        <InputText id='store_name'
                                   type='text'
                                   v-model="model.store_info.name"
                        />
                        <label for='store_name'>Online Store</label>
                    </span>
                </div>

                <div class='field col-12  md:col-4'>
                    <span class='p-float-label'>
                        <InputText id='store_url'
                                   type='text'
                                   v-model="model.store_info.url"
                        />
                        <label for='store_url'>Store URL</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <Panel :toggleable='true' class='col-12 sidebar-like'>
                    <template #header>
                        Capitoli
                    </template>
                    <ChaptersManager v-if='model["last_update"] > 0' v-model='model.chapters'/>
                    <span v-else>Salva la traduzione per accedere alla gestione dei capitoli</span>
                </Panel>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import Uploader from '../../../mixins/uploader';
import DropdownPublishers from '../../../mixins/dd_publishers';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/CompleteBooks');
const commonStore = createNamespacedHelpers('Common');

import useVuelidate from '@vuelidate/core'
import ChaptersManager from './chapters/ChaptersManager';

import tools from '../../../libs/tools';
import model from './model';

export default {
    data(){
        return {
            Barcode: null,
            binary: [
                { value: false, label: 'No' },
                { value: true, label: 'Yes' },
            ]
        };
    },
    components: {
        ChaptersManager
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.translationValidation;
    },
    beforeUnmount() {
        this.resetUploads(model.uploads);
    },
    computed: {
        ...mapGetters(['row', 'loading']),
        ...commonStore.mapGetters(['languages', 'publish_states']),
        openUrl(){
            if (!this.model.audio || !this.model.audio.url) return '';
            return this.model.audio.url.replace(process.env.VUE_APP_CLOUDFRONT_PROTECTED, process.env.VUE_APP_CLOUDFRONT_OPEN + '/audio');
        }
    },
    watch: {
        row(n){
            this.initModel(n);
        },
        lang(){
            this.initModel(this.row);
        },
        dirty(n){
            if (n) this.formIsDirty();
            else this.formIsClean();
        },
        'model.lastUpload'(n){
            if (!n) return;
            if (this.model.repo) this.model.repo += "|";
            this.model.repo += n;
        }
    },
    mounted() {
        this.initModel(this.row);
        this.prepareUploads(model.uploads, this.lang);
    },
    methods: {
        ...mapMutations(['formIsDirty', 'formIsClean']),
        ...mapActions(['saveTranslation']),

        async save () {
            await this.v$.$validate();
            if (this.v$.model.$invalid) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }

            this.saveTranslation({
                data: model.formatTranslation(this.model),
                parent_id: model.translationParentId(this.model)
            }).then(() => {
                this.formIsClean();
                this.ShowSuccess('Ottimo', 'Traduzione salvata con successo');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initModel(mod){
            this.dirty = false;
            const base = model.newTranslation(this.id, this.lang);
            if (mod && mod.translations && mod.translations[this.lang]){
                this.model = tools.models.fillTranslation(model.parseTranslation(mod.translations[this.lang]), base);
                if (!this.model.audio){
                    this.model.audio = {
                        url: "",
                        seconds: 0
                    };
                }
                if (!this.model.store_info){
                    this.model.store_info = base.store_info;
                }
                if (!this.model.chapters){
                    this.model.chapters = [];
                }
                return;
            }

            this.model = base;
        }
    },
    props: {
        lang: {
            type: String,
            default: 'it'
        },
        id: {
            type: String
        }
    },
    mixins: [
        Navigations, Notifications, Uploader, DropdownPublishers
    ]
}
</script>
